.wrapper {
  max-width: 100vw;
  width: 100%;
  background: linear-gradient(180deg, #2962ff 0%, #1a377c 50%, #060d1f 100%);
  padding: 16px 24px 0px 24px;
  position: fixed;
  bottom: 0px;
  left: 0;
  border-radius: 35px 35px 0 0;
  transition: 2s;
  z-index: 19;
}

.green {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.blue {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.red {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.purpleBig {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.redBig {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.darckGreenBig {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.goldBig {
  background: linear-gradient(180deg, #060d1f 0%, #2962ff 100%);
}

.hole {
  background-color: black;
  width: 72px;
  height: 8px;
  margin: 0 auto;
  border-radius: 9px;
  margin-bottom: 32px;
}

.loaderWrap {
  width: 75px;
  transform-origin: center;
  animation: spin 2s linear infinite, blink 1s infinite;
}

.iconWrap {
  margin-bottom: 32px;
}

.titleWrap {
  max-width: 276px;
  margin-bottom: 96px;
}

.title {
  font-family: Manrope, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 57.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  max-width: 302px;
  width: 100%;
  margin-bottom: 16px;
}

.description {
  max-width: 310px;
  width: 100%;
  font-size: 16px;
  color: #ffffff69;
  margin-bottom: 102px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* table */

.tableHeader {
  font-size: 40px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

.RefListWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 50vh;
  overflow: scroll;
}

.listElement {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 65px;
}

.listTitle {
  font-size: 16px;
  opacity: 64%;
  margin-bottom: 2px;
}

.listAmount {
  font-size: 32px;
  letter-spacing: -0.02em;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.statick {
  position: static;
}

.relative {
  position: relative;
  margin-top: 20%;
}

.close {
  position: absolute;
  right: 27px;
  top: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  opacity: 0.4;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  z-index: 8;
}
