.wrapper {
  max-width: 100vw;
  width: 100%;
  max-height: 320px;
  background: linear-gradient(180deg, #2962FF 0%, #1A377C 50%, #060D1F 100%);
  padding: 64px 24px 48px 24px;
  position: fixed;
  bottom: 64px;
  left: 0;
  border-radius: 35px 35px 0 0;
  transition: 2s;
  z-index: 12;
}


.green {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.blue {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.red {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.purpleBig {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.redBig {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.darckGreenBig {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.goldBig {
  background: linear-gradient(180deg, #060D1F 0%, #2962FF 100%);
}

.hole {
  background-color: black;
  width: 72px;
  height: 8px;
  margin: 0 auto;
  border-radius: 9px;
  margin-bottom: 32px;
}

.loaderWrap {
  width: 75px;
  transform-origin: center;
  animation: spin 2s linear infinite, blink 1s infinite;
}

.iconWrap {
  margin-bottom: 32px;
}

.titleWrap {
  max-width: 276px;
  margin-bottom: 24px;
}

.title {
  font-family: Manrope, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  color: #ffffff; 
  /* text-align: center; */
  margin-bottom: 16px; 
  text-align: left;
}


.description {
  max-width: 310px;
  width: 100%;
  font-size: 16px;
  color: #ffffff69;
  margin-bottom: 102px;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* table */

.tableHeader {
  font-size: 40px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

.RefListWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 50vh;
  overflow: scroll;
}

.listElement {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 65px;
}

.listTitle {
  font-size: 16px;
  opacity: 64%;
  margin-bottom: 2px;
}

.listAmount {
  font-size: 32px;
  letter-spacing: -0.02em;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.statick {
  position: static;
}

.relative {
  position: relative;
  margin-top: 20%;
}

.close {
  position: absolute;
  right: 27px;
  top: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; 
  background: none; 
  border: none; 
  outline: none;
  opacity: 0.4;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4); 
  backdrop-filter: blur(6px); 
  z-index: 8;
}

.confirmButton {
  width: 342px;
  height: 64px;
  border-radius: 8px;
  background-color: #F62C36;
  color: #FFFFFF;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 16.2px;
  border: none; 
  cursor: pointer; 
  text-align: center; 
  display: flex;
  align-items: center;
  justify-content: center; 
  transition: background-color 0.3s ease;
}

.confirmButton:hover {
  background-color: #D62A30; 
}

.confirmButton:active {
  background-color: #C0292E; 
}