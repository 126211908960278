@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&family=Roboto+Condensed:wght@500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  @apply m-0 antialiased flex justify-center;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #060d1f;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  min-width: 100vw;
  overflow-y: scroll;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 390px;
  background: linear-gradient(180deg, #2962ff 0%, #060d1f 100%);
  z-index: -1;
}

#app-container {
  max-width: 390px;
  width: 100vw;
  position: relative;
  padding-bottom: 70px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fill-progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.before\\:animate-fill-progress::before {
  animation: fill-progress 2s linear infinite;
}
